// Dependencies
import React from "react"
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Components
import Layout from "../components/Layout";
import Information from "../components/Information";
import Img from "../components/Img";
import RichText from "../components/RichText";
import Stickers from "../components/Stickers"

const Download = ({ thumbnail, files }) => {

  return (
    <div className="">
      <Img {...thumbnail} />
      <div className="downloads">
        <span>Download: </span>
        {files.map((item, index) => {
          console.log(item)
          return (
            <a href={item.asset.file.url} download={item.filename} target="_blank" className="small-btn" key={`${item.id}-${index}`}>{item.fileType}</a>
          )
        })}
      </div>
    </div>
  )
}

const Guidelines = ({ data }) => {

  const { title, subtitle, primaryLogos, secondaryLogos, typography, colour, stickers } = data.allContentfulGuidelines.nodes[0];

  return (
    <Layout title="Brand Guide">
      <div className="pad">
        <p>
          {title} <br />
          {subtitle}
        </p>

        <section className="mbx4">
          <h1>1. Primary Logo</h1>
          <div className="grid">
            {primaryLogos.map((item, index) => <Download {...item} key={item.id} />)}
          </div>
        </section>


        <section className="mbx4">
          <h1>2. Secondary Logo(s)</h1>
          <div className="grid">
            {secondaryLogos.map((item, index) => <Download {...item} key={item.id} />)}
          </div>
        </section>

        <section className="mbx4 has-links">
          <h1>3. Typography</h1>
          <RichText {...typography} />
        </section>

        <section className="mbx4">
          <h1>4. Colour</h1>
          <RichText {...colour} />
          <div className="padx4 flex-center">
            <div className="align-center">
              <div className="sticker white-on-red">#ED1B24</div>
              <div className="sticker white-on-green">#01A64B</div>
              <div className="sticker white-on-blue">#007AC3</div>
              <div className="sticker black-on-yellow">#FEE800</div>
              <div className="sticker black-on-grey">#E5E5E5</div>
              <div className="sticker">#FFFFFF</div>
              <div className="sticker white-on-black">#000000</div>
            </div>
          </div>
        </section>

        <section className="mbx4">
          <h1>5. Stickers</h1>
          <RichText {...stickers} />
          <div className="sticker-board">
            <div className="pabs fs">
              <Stickers className="fs" />
            </div>
          </div>
        </section>

      </div>

      <Information />
    </Layout>
  )
}

Guidelines.propTypes = {
  data: PropTypes.object.isRequired
}

export default Guidelines

export const ContentfulDownloadFragment = graphql`
  fragment ContentfulDownloadFragment on ContentfulDownload {
    id
    title
    thumbnail {
      contentful_id
      file {
        url
      }
      fluid(maxHeight: 1080) {
        ...GatsbyContentfulFluid_noBase64
      }
    }
    files {
      id
      filename
      fileType
      asset {
        file {
          url
        }
      }
    }
  }
`

export const GuidelinesPageQuery = graphql`
  query GuidelinesQuery {
    allContentfulGuidelines {
      nodes {
        title
        subtitle
        primaryLogos {
          ...ContentfulDownloadFragment
        }
        secondaryLogos {
          ...ContentfulDownloadFragment
        }
        typography {
          json
        }
        colour {
          json
        }
        stickers {
          json
        }
      }
    }
  }
`
