// Dependencies
import React, { useEffect, useRef, useState } from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
// import useTimeout from "../hooks/useTimeout";
// Components
// import Image from "../components/Image";

// const COLORS = [
//   'yellow-on-blue',
//   'red-on-black',
//   'yellow-on-red',
//   'green-on-red',
//   'green-on-black',
//   'black-on-red',
//   'black-on-yellow',
//   'red-on-grey',
//   // x2
//   'yellow-on-blue',
//   'yellow-on-red',
//   'green-on-red',
//   'red-on-grey',
//   'black-on-red',
//   'black-on-yellow',
//   // 'black-border'
// ];

const COLORS = [
  [
    'yellow-on-blue',
    'red-on-black',
    'yellow-on-red',
    'red-on-green',
    'black-border',
    'blue-on-yellow',
    'green-on-blue',
    'blue-on-grey',
    'green-on-black',
    'white-on-blue',
    'red-on-grey',
    'black-on-red',
    'yellow-on-blue',
    'red-on-green',
    'black-border',
    'blue-on-yellow',
    'blue-on-green',
    'black-on-yellow',
    'red-on-grey',
    'black-on-red',
  ],
];
const WORDS = [
  'No', 'Such', 'Thing', 'As', 'Standard', 'Park', 'Royal', 'London', 'Drive', 'Slow', 'Think', 'Hard', 'Standard', 'Studios'
];

const SPEED = 1333;

const select = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)]
}

const Sticker = ({ x, y, word, color, index }) => {

  const [show, set] = useState(true);
  // const colors = useRef(select(COLORS));
  const rotate = useRef(
    (Math.random() * 80) - 40
  );

  // Hide aftr some time
  // let timer;
  // useEffect(() => {
  //   timer = setTimeout(() => {
  //     set(false)
  //   }, 20000 + (1000 * index))
  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [])

  return (
    show && (
      <div className={`sticker ${color}`} style={{
        top: `${y}%`,
        left: `${x}%`,
        transform: `translate(-50%, -50%) rotate(${rotate.current}deg)`
      }}>{word}</div>
    )
  )
}

const Stickers = ({ className = "stickers" }) => {

  const timer = useRef();
  const colors = useRef(select(COLORS));
  const [wordCount, setWordCount] = useState(-1);
  const [colourCount, setColourCount] = useState(0);
  const [pack, updatePack] = useState([]);

  const stick = (x, y, wordCount) => {
    updatePack(prevPack => {
      setColourCount(prev => {
        return prev < colors.current.length - 1 ? prev + 1 : 0
      })
      return [
        ...prevPack,
        {
          word: WORDS[wordCount],
          color: colors.current[colourCount],
          x: x,
          y: y
        }
      ]
    })
  }

  // let count;
  // const handleClick = ({ clientX, clientY }) => {
  //   const left = (clientX / window.innerWidth) * 100;
  //   const top = (clientY / window.innerHeight) * 100;
  //   count = count < WORDS.length - 1 ? count + 1 : 0;
  //   // setWordCount(prev => {
  //   //   return prev < WORDS.length - 1 ? prev + 1 : 0
  //   // })
  //   stick(left, top, count);
  // }
  //
  // useEffect(() => {
  //   window.addEventListener('click', handleClick, false);
  //   return () => {
  //     window.removeEventListener('click', handleClick, false);
  //   }
  // }, [])

  // TIMER VERSION
  const counter = () => {
    clearTimeout(timer.current);
    setWordCount(prev => {
      return prev < WORDS.length - 1 ? prev + 1 : 0
    })
    timer.current = setTimeout(counter, SPEED);
  }

  const reset = () => {
    setWordCount(-1)
    updatePack([])
  }

  useEffect(() => {
    timer.current = setTimeout(counter, SPEED);
    window.addEventListener('click', reset, false);
    return () => {
      window.removeEventListener('click', reset, false);
      clearTimeout(timer.current);
    }
  }, [])

  useEffect(() => {
    const x = Math.floor(Math.random() * 90);
    const y = Math.floor(Math.random() * 90);
    if (wordCount >= 0) {
      stick(x, y, wordCount)
    }
  }, [wordCount])

  return (
    <div className={className}>
      {pack.map((item, index) => (
        <Sticker key={`stick-${index}`} {...item} index={index} />
      ))}
    </div>
  )
}

export default Stickers
